<template>
  <div class="admin-layout-wrapper">
    <div
      class="admin-header bg-gray-5 c-bg box-shadow-4 flex gap-m flex-middle"
      style="border-bottom: 1px solid var(--c-gray-2)">
      <div class="side-spacer flex-center flex-middle padding-l-horizontal" style="width: 220px; flex: 0 0 220px">
        <div class="h1 margin-remove margin-auto c-bg text-overflow-ellipsis cursor-pointer"
             @click="$router.push('/')"
             style="font-size: calc(var(--font-2xl) * 1.2)">
          {{ translate('core.meta.siteName') }}
        </div>
      </div>
      <div class="width-expand padding-m-vertical padding-l-horizontal">
        <div class="inner flex gap-xl">
          <div class="search-widget" style="max-height: 30px">
            <!--
            <primary-search
              class="flex-middle max-width-400"
              :input-props="{ icon: 'magnify', size: 's' }"></primary-search>-->
          </div>
          <div
            class="end-widgets flex gap-l flex-stretch"
            style="margin-inline-start: auto">
            <div class="profile-widget flex gap-m flex-middle bg-bg cursor-pointer"
            @click="goToProfile">
              <user-avatar size="xs" v-bind="userProfile"></user-avatar>
              <icon theme="gray-5" icon="pencil"></icon>
            </div>
            <div class="notification-widget flex-middle " >
              <notification-widget :dropdown-props="{class: 'c-text'}"></notification-widget>
            </div>
            <div class="home-widget flex-middle cursor-pointer" @click="$router.push('/')"><!--arrow-login-inline-end -->
              <icon theme="bg" icon="home" :size="1.5"  ></icon>
            </div>
            <div class="logout-widget flex-middle cursor-pointer"  @click="logout"><!--arrow-login-inline-end -->
              <icon theme="bg" icon="arrow-login-inline-end" :size="1.5" ></icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--     <router-link :to="{ path: '/' }" class="uk-navbar-item">
          <div class="icon-wrap bg-gray-1 padding-xs radius-sharp">
            <icon size="2" icon="cube"></icon>
          </div>
        </router-link>
        <router-link v-if="!isLoggedIn" tag="a" :to="{ name: 'user-login' }"
        >Login</router-link
        >
        <a
          v-if="isLoggedIn"
          class="icon-wrap bg-gray-1 padding-xs radius-sharp block"
          @click="logout">
          <icon class="" size="2" icon="arrow-logout-inline-end"></icon>
        </a> -->

    <div class="background-bg-4 admin-outer-grid flex gap-m">
      <div class="container side-menu-container container-s padding-m">
        <div
          class="radius-s padding-m bg-bg flex-column relative"
          style="height: 100%; height: calc(100vh - 120px)">
          <div class="logo flex flex-center flex-middle margin-l-top margin-xl-bottom cursor-pointer"
               @click="$router.push({name:'admin-dashboard' })">
            <img
              :src="getDynamicAssetUrl('images/logos/main.png')"
              style="height: 50px; width: 50px" />
          </div>
          <base-side-nav
            v-if="sideNavLinks"
            style="height: 100%; max-height: 100%"
            :items="sideNavLinks"></base-side-nav>
          <spinner v-else overlay="absolute" size="4"></spinner>
        </div>
      </div>
      <div class="main-content-wrapper width-expand margin-l-bottom">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watchEffect } from "vue";
import BaseSideNav from "@/client/components/nav/BaseSideNav.vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { useStore } from "vuex";
import UserAvatar from "@/client/components/chat/UserAvatar";
import NotificationWidget from "@/client/components/etc/NotificationWidget";
//import PrimarySearch from "@/client/applications/justopp/components/widgets/PrimarySearch";
export default {
  components: { BaseSideNav, UserAvatar, NotificationWidget,  },//PrimarySearch
  props: {
    defaultComponent: String,
    logo: {
      type: String,
      default: "logos/main.png",
    },

  },
  setup: function (props) {
    let { asyncOps, asyncOpsReady } = asyncOperations(props, useStore());

    return { asyncOps, asyncOpsReady };
  },
  data: function () {
    return {
      sideNavLinks: false,
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters["user/profile"];
    },
    isloggedStatusKnown() {
      return computed(() => this.$store.getters["user/isTokenValid"] !== null);
    },
    isLoggedIn() {
      return computed(() => this.$store.getters["user/isTokenValid"]);
    },
    logoStyle() {
      let url = utilities.requireAsset("images/" + this.logo);
      return {
        backgroundImage: "url('" + url + "')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 5px",
        height: "78px",
        minHeight: "0",
        backgroundSize: "60px auto",
      };
    },
  },
  async created() {
    let navFactory = await this.getDynamicAsset(`core/nav/adminSideNavFactory.js`);
    let getter = typeof navFactory === "function" ? navFactory : navFactory.default;
    this.sideNavLinks = await getter({
      asyncOperations: this.asyncOps,
      store: this.$store,
    });
  },
  methods: {
    logout() {
      this.$store.commit("user/invalidateAuth");
      this.$store.commit("user/clearProfile");
      this.$router.push("/");
    },
    goToProfile() {
      this.$router.push({
        name: "entity-edit",
        params: {
          entityType: "user",
          entityId: this.userProfile.id,
        },
      });
    }
  },
};
</script>

<style scoped lang="scss">
.admin-layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.admin-outer-grid {
  flex-direction: row;
  flex: 100%;
}

.side-menu-container,
.container.side-menu-container {
  width: 220px;
  flex: 0 0 220px;
  padding-inline-end: 0;
}

.side-menu {
  width: 100%;
  flex: 100%;

  .side-menu-logo {
    display: block;
    height: 100px;
    width: 100px;
    margin: var(--margin-xl) auto var(--margin-l) auto;
  }

  .side-nav {
    list-style: none;
    padding-inline-start: 20px;

    li {
      margin-bottom: var(--margin-l);
    }

    a:not(.logo) {
      display: block;
      width: 100%;
      list-style: none;
      color: var(--c-bg);
      font-size: var(--font-l);
      padding: var(--margin-s);
      position: relative;
      padding-inline-start: var(--margin-s);
      padding-inline-end: var(--margin-s);
      transform: none;
      transition: padding 100ms ease-in;

      &.uk-active,
      &.active,
      &:hover {
        text-decoration: none;
        padding-inline-start: var(--margin-m);

        &:before {
          position: absolute;
          content: " ";
          width: 3px;
          height: 100%;
          inset-inline-start: 0;
          top: 0;
          background: var(--c-alt);
        }
      }
    }
  }
}

.admin-notification-widget {
  .notification-icon {
    position: relative;
    cursor: pointer;

    .notification-badge {
      position: absolute;
      top: -70%;
      right: -50%;
      z-index: 2;
    }
  }
}

.profile-widget {
  border-radius: var(--border-radius-l);
  padding-inline-end: var(--margin-m);
}
</style>
